@import "~ngx-toastr/toastr";
@import "intl-tel-input/build/css/intlTelInput.css";
body {
    overflow-x: hidden;
}

.confirm-btn-container>button {
    width: 100% !important;
}

label.star {
    color: white;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.bg-primary-custom {
    background-color: #242965 !important;
    color: white !important;
}

.badge-secondary-custom {
    background-color: #890137 !important;
    color: white !important;
}

input.star-5:checked~label.star:before {
    color: #e7a91c !important;
    text-shadow: none !important;
}

.btn-link,
a {
    text-decoration: none !important;
}

.item-custom {
    position: relative !important;
    background-color: #232f3e !important;
}

.multiselect-item-checkbox-div {
    color: #ffffff !important;
}

.multiselect-item-checkbox-div:hover {
    color: #1e2125 !important;
}

.overflow-wrap {
    overflow-wrap: break-word !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    max-width: 100% !important;
    margin-bottom: 4px !important;
}

.popover .popover-header h3 {
    color: black !important
}

.timepicker-overlay {
    z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
    z-index: 10000000 !important;
}

.divth th {
    font-weight: 500 !important;
}
.divth td {
  font-weight: 500 !important;
}

.divth {
    font-weight: 600 !important;
}

.divth {
  padding: 0% !important;
}



/* You can add global styles to this file, and also import other style files */

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545 !important;
}

iframe {
    width: -webkit-fill-available !important;
}

.mh-300 {
    min-height: 300px !important;
}

.mh400 {
    min-height: 400px !important;
}

.wba {
    word-break: break-all;
}

.w200 {
    width: 200px;
}

.fs-14 {
    font-size: 14px !important;
}

.fw700 {
    font-weight: 700 !important;
}

.fw400 {
    font-weight: 400 !important;
}

.cursor{
  cursor: pointer;
}

.table-border-custom {
    border: 1px solid #acb1b6 !important;
}

.mh500 {
    min-height: 500px;
}

.nav-link,
.nav-link:focus,
.nav-link:hover {
    color: #161ddd;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
    font-size: 14px !important;
    font-weight: 700 !important;
}

.export-elements {
    display: none;
}

kendo-pdf-document .export-elements {
    display: block;
}

kendo-pdf-document .pdf_cls {
    display: none;
}

.w-90{
  width: 90% !important;
}

.w-80{
  width: 80% !important;
}

.w-70{
  width: 70% !important;
}

.w-60{
  width: 60% !important;
}

.w-40{
  width: 40% !important;
}

.w-30{
  width: 30% !important;
}

.w-20{
  width: 20% !important;
}


.no-scroll {
  overflow-y: hidden !important;
}

.no-scroll-x {
overflow-x: clip !important;
}


.listDropdownColor li a {
  color: #ffffff;
}

.listDropdownColor li a:hover {
  color: #000000 !important;
}

.listDropdownColor li a:active {
  color: #000000 !important;
  background-color: white !important;
}

.listDropdownColor {
  background-color: #232f3e !important;
}

/* .divth {
  padding: 0 !important;
} */

.word-break{
word-break: break-all;
}


.dropdown-list ul.item2 {
  max-height: 197px;
  position: relative;
}

.fw600{
  font-weight: 600 !important;
}

.break-word{
  word-break: break-word;
}
